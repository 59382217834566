<template>
  <div id="damage">
    <div class="columns">
      <div class="column">
        <p class="title has-text-grey-light is-6">Condition status</p>
      </div>
      <div class="column is-narrow">
        <p class="title is-6">
          <span class="has-text-danger" v-if="condition">Report data</span>
          <span class="has-text-success" v-else>Nothing reported</span>
        </p>
      </div>
    </div>
    <div v-if="condition">
      <div class="box" v-for="(item, index) in items" :key="index">
        <ConditionItem :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutoCheckCondition',
  components: {
    ConditionItem: () => import('./AutoCheckConditionItem')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    condition() {
      return this.data.count
    },
    items() {
      return this.data.list
    }
  }
}
</script>
